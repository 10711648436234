import React from "react";
import classNames from "classnames";
import { Container, Text, Image, AnimateIn } from "@atoms";

const LogoGrid = ({ copy, className: _className, groups }) => {
  return (
    <section
      className={classNames(
        "border-t-2 border-black py-20 md:py-28",
        _className
      )}
    >
      <Container>
        <div className="flex flex-col items-center justify-center">
          <Text richText className="text-center">
            {copy}
          </Text>
          {!!groups.length && (
            <div className="mt-16 flex w-full flex-wrap gap-y-12">
              {groups.map((group, i) => {
                const { uid, logos, category } = group;
                return (
                  // the group container
                  <AnimateIn
                    key={uid}
                    preset="fadeUpFast"
                    delay={0.2 * (i + 1)}
                    className={classNames("w-full md:w-auto", {
                      "md:flex-[1_1_50%]": logos?.length >= 2,
                      "md:flex-[0_1_calc(25%-1rem)]": logos?.length <= 1,
                    })}
                  >
                    {/* the logo + category group */}
                    <div
                      className={classNames(
                        "flex h-full w-full flex-col justify-between gap-6 border-black md:gap-12",
                        {
                          "md:border-r-2 md:pr-12": i % 3 === 0,
                          "md:px-12": i % 3 === 1,
                          "md:border-l-2 md:pl-12": i % 3 === 2,
                        }
                      )}
                    >
                      {!!logos?.length && (
                        // the logos container
                        <div className="flex flex-wrap items-center justify-center gap-12">
                          {logos.map(logo => {
                            return (
                              // inidividual logo
                              <div
                                key={logo.uid}
                                className={classNames("w-1/3", {
                                  "md:flex-[0_1_calc(50%-1.5rem)]":
                                    logos.length >= 2,
                                  "md:flex-1": logos.length <= 1,
                                })}
                              >
                                <Image image={logo.image} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {category && (
                        <Text variant="sm" className="w-full text-center">
                          {category}
                        </Text>
                      )}
                    </div>
                  </AnimateIn>
                );
              })}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

LogoGrid.defaultProps = {};

export default LogoGrid;
